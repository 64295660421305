/* eslint-disable jsx-a11y/anchor-is-valid */

import React, { useEffect } from "react"
import Container from "react-bootstrap/Container"
import { useTranslation, Trans } from "react-i18next"

import i18next from "../i18n/config"

/* Images */
import NavBarLogo from "../images/logo.svg"

/* Components */
import Wrapper from "../components/wrapper/wrapper"
import SEO from "../components/seo/seo"
import NavBar from "../components/navbar/navbar"
import Main from "../components/main/main"
import Faqs from "../components/faqs/faqs"
import FaqsItem from "../components/faqs-item/faqs-item"
import Footer from "../components/footer/footer"

export default function FaqsPage({ location }) {
  const { t } = useTranslation()

  const PageTitle = t("pages.faqs.title")

  const FaqsTitle = t("pages.faqs.questions.title")
  const FaqsItems = t("pages.faqs.questions.items")

  const RenderFaqsItems = []

  for (const [index, faq] of FaqsItems.entries()) {
    const i18nKey = `pages.faqs.questions.items.${index}.answer`
    RenderFaqsItems.push(
      <FaqsItem
        key={index}
        index={index}
        classVariant="faqsItemSimple"
        title={faq.title}
      >
        <Trans i18n={i18next} i18nKey={i18nKey}>
          {faq.answer}
          <a>.</a>
        </Trans>
      </FaqsItem>
    )
  }

  useEffect(() => {
    window.scrollTo(0, 0)
  }, [])

  return (
    <Wrapper>
      <SEO title={PageTitle} />
      <NavBar classVariant="navBarDefault" logo={NavBarLogo} />
      <Main>
        <Container>
          <Faqs title={FaqsTitle} classVariant="faqsWrapperLarge" id="faq">
            {RenderFaqsItems}
          </Faqs>
        </Container>
      </Main>
      <Footer />
    </Wrapper>
  )
}
